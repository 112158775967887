import Axios from 'axios';
import Cookies from 'js-cookie';

if (!Cookies.get("auth-token")) {
    Cookies.set("auth-token", "eyJpdiI6Ii9sZnlpVGpaYU5RR0NUdGtZVC9hdVE9PSIsInZhbHVlIjoiNWZZdWJmTEU5MjJkbWhsYkxiNWx5QT09IiwibWFjIjoiMGUwY2Q0MWJkODgyYWE0MmIwZmJhMjJiMWVjOWZiZjFmYTE1MjBiOTg2ZjFkY2M0YjVjMTJjYWYzOTM1MmZjYSIsInRhZyI6IiJ9");
    console.log('set cookie');
}

let authToken = Cookies.get("auth-token");
const apiUrl = process.env.REACT_APP_API_URL;

export default {

    register: async function(email, password) {
        return await post("/users/register", {email, password});
    },

    refreshToken: function () {
        authToken = Cookies.get("auth-token");
    },

    activityEvents: async () => {
        return await get("/activity_events");
    },

    markNotificationsAsRead: async () => {
        return await post("/activity_events/mark_as_read");
    },

    user: async () => {
        return await get("/users/handshake");
    },

    signIn: async (username, password) => {
        return await post("/users/sign_in", { 'email': username, 'password': password });
    },

    createProject: async (title, healthName) => {
        return await post("/projects", { 'title': title, 'health_name': healthName });
    },

    completeProject: async (projectId) => {
        return await post("/projects/complete", { 'project_id': projectId });
    },

    startProjectStage: async (projectId, healthName) => {
        return await post("/projects/start_stage", { 'project_id': projectId, 'health_name': healthName });
    },

    completeSession: async (session_id, project_id, par, final, rank, thought_label) => {
        return await post("/sessions/complete", { session_id, project_id, par, final, rank, thought_label });
    },

    levelUpSession: async (session_id) => {
        return await post("/sessions/level_up", { session_id });
    },

    suggestThoughts: async (project_id) => {
        return await get("/sessions/thought_options", { project_id });
    }
};

async function get(url, params, raw) {
    console.log('get');
    try {
        const response = await Axios.get(apiUrl + url, { headers: headers(), params });
        if (raw) {
            console.log('raw', raw);
            return response;
        }
        console.log('response', response, response.data);
        return readResponse(response);
    } catch (err) {
        console.log('err', err);
        return false;
    }
}

async function put(url, resource) {

}

async function post(url, resource) {
    try {
        console.log('resource', resource);
        const response = await Axios.post(apiUrl + url, resource, { headers: headers() });
        console.log('response', response);
        return readResponse(response);
    } catch (err) {
        console.log('err', err);
        return false;
    }
}

function readResponse(response) {
    const { data } = response;



    if (data.success !== true) {

        console.log(data);

        return false;
    }

    return data.data;
}

function headers() {
    //console.log('cookie headers', authToken);
    return {
        "X-Auth-Token": authToken
    };
}

function isIos() {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
};