import logo from './logo.svg';
import './App.scss';
import './Mobile.scss';
import { useState } from 'react';
import Back from './Back';

import CookieConsent from "react-cookie-consent";


function App() {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [showSignUp, setShowSignUp] = useState(false);
  const [showSignIn, setShowSignIn] = useState(false);

  const [signInError, setSignInError] = useState(null);

  const handleCtaClick = (e) => {
    setShowSignUp(true);
  }
  const handleSignInClick = (e) => {
    setShowSignIn(true);
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  }
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  }

  const handleSubmitClick = async () => {
    window.rdt('track', 'SignUp', {
        "currency": "USD",
        "value": 40
    });

    const response = await Back.register(email, password);

    if (!response) {
      return;
    }

    const url = process.env.REACT_APP_APP_URL + "?gate=" + response.token;

    window.location.replace(url);
  }
  const handleSignInSubmitClick = async () => {

    const response = await Back.signIn(email, password);

    if (!response) {
      setSignInError("Please check your email and password and try again.")
      return;
    }

    const url = process.env.REACT_APP_APP_URL + "?gate=" + response.token;

    window.location.replace(url);
  }

  return (
    <div className="App">
      <header className="App-header">
        <div className="Logo">
          {/* <div className="text">fun at work</div> */}
          <img src="logo-square.png" className="logo-img" />
        </div>
        <div className="SignIn">
          <div className="signin-link" onClick={handleSignInClick}>Log In</div>
        </div>
      </header>

      <main className="App-main">
        
      <div className='BrowserShot-position'>
        <div className="BrowserShot">
            <div style={{backgroundImage: "url(/browser-shot2.jpg)"}} className="browser-img"></div>
          </div>
        </div>
        <div className="Headline">
          {/* <div className='heading'>
            From<br />
            <span className="heading-bad">Work sucks</span> to<br />
            <span className="heading-good">I've got this</span>.
          </div> */}
          <div className='heading'>
            {/* Save Lives<br />While You Work.< br /> */}
            {/* Find Your Flow<br />Fast. */}

            {/* Win Back Your<br />Workday. */}

            We're designing a tool to help you 
            focus on your important work and feel awesome doing it.

            {/* Do Your Best<br />Work. */}
            {/* Find Your Focus<br />Fast. */}

            {/* Find Your Focus<br />Fast. */}
            {/* Flick On Your<br />Focus. */}

            {/* <span className="heading-good">You've Got This</span>. */}
          </div>
          <div className="icon-position">
            <img src="/flex.png" className="flex-icon-img" />
          </div>
          <div className="icon-position">
            <img src="/scream.png" className="scream-icon-img" />
          </div>
          <div className='sub-cta'>
            <div className='subheading'>
              We'd love your feedback during our beta.
            </div>
            <div className='sub-cta'>
              {/* <div className='cta' onClick={handleCtaClick}>
                Join Beta
              </div> */}
            </div>
          </div>
          <div className='SubSignUp' style={{display: showSignUp ? 'block' : 'block', opacity: showSignUp ? '1' : '1'}}>
            <div className="form-heading">Free forever for beta users</div>
            <div className="form">
              <input type="text" className="input-text" placeholder='Email' onChange={handleEmailChange} style={{background: 'url(email.png) #fff no-repeat scroll 17px 18px'}} />
              <input type="password" className="input-text" placeholder='New Password' onChange={handlePasswordChange} style={{backgroundSize: '17px 17px', background: 'url(password.png) #fff no-repeat scroll 17px 17px'}} />
              <input type="submit" className="submit-button gtm-trial" value="Join Beta" onClick={handleSubmitClick} />
            </div>

            <div className="terms">
            By signing up, you agree to our&nbsp;<a className="footer-link" target="_blank" href="https://account.funatwork.co/terms">Terms of Service</a> & <a className="footer-link" target="_blank" href="https://account.funatwork.co/privacy">Privacy Policy</a>
            </div>
          </div>
          {/* <div className='subheading-profits'>
            <span className="profits">After our beta, 100% of profits will be donated.</span>&nbsp;We'll raise money through our sponsors, crowdsourcing, and premium upgrades.
          </div> */}
          <div className="footer">
            Copyright &copy; 2022, funatwork. <a className="footer-link" target="_blank" href="https://account.funatwork.co/terms">Terms of Service</a> &bull; <a className="footer-link" target="_blank" href="https://account.funatwork.co/privacy">Privacy Policy</a>
          </div>
        </div>
      </main>

      <div className="SignInPage" style={{display: showSignIn ? 'block' : 'none', opacity: showSignIn ? '1' : '0'}}>
        <div className="modal">
          <div className="heading">Log In</div>
          <div className="subheading">Sign back into your existing account.</div>
          {signInError && <div className="form-error">{signInError}</div>}
          <div className="form">
            <input type="text" className="input-text" placeholder='Email' onChange={handleEmailChange} style={{background: 'url(email.png) #fafafa no-repeat scroll 17px 18px'}} />
            <input type="password" className="input-text" placeholder='Password' onChange={handlePasswordChange} style={{backgroundSize: '17px 17px', background: 'url(password.png) #fafafa no-repeat scroll 17px 17px'}} />
            <input type="submit" className="submit-button gtm-trial" value="Log in" onClick={handleSignInSubmitClick} />
          </div>
        </div>
        <div className="backfill" style={{opacity: showSignIn ? '0.5  ' : '0.8'}}></div>

      </div>

      <CookieConsent
        location="bottom"
        buttonText="Accept All"
        cookieName="cookies-accept"
        style={{ color: "#666", background: "#fff", borderTop: '2px solid #eee' }}
        buttonStyle={{ color: "#4e503b", fontSize: "17px", padding: "10px 16px", borderRadius: "4px", marginRight: "40px" }}
        expires={150}
      >
        <span style={{padding: "0 0 0 40px"}}> We use cookies to enhance your browsing experience, serve personalized ads or content, and analyze our traffic.</span>
        
      </CookieConsent>
    </div>
  );
}

export default App;
